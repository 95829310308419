'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  const translation = useTranslations('Error');

  return (
    <html>
      <body>
        <h2>{translation('title')}</h2>
        <p className="mt-2 text-xl font-semibold">{translation('text')}</p>
        <p className="mt-2 text-pretty text-sm">{error.message}</p>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  );
}
